<template>
  <div class="qr-code-page">
    <div class="qr-code-container">
      <vue-qrcode v-if="qrValue" :value="qrValue" class="qr-code" />
      <div v-else class="loading-icon">
        <i class="fas fa-spinner fa-spin fa-3x"></i>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode'
import firebaseApp from '@/firebase/init'

export default {
  name: 'QrBarcodeTimeSensitive',
  components: {
    VueQrcode
  },
  data () {
    return {
      qrValue: null,
      fetchInterval: null
    }
  },
  mounted () {
    this.fetchQrValue()
    this.fetchInterval = setInterval(this.fetchQrValue, 4 * 60 * 1000) // 4 minutes
    window.addEventListener('focus', this.handleFocus)
  },
  beforeDestroy () {
    clearInterval(this.fetchInterval)
    window.removeEventListener('focus', this.handleFocus)
  },
  methods: {
    fetchQrValue () {
      const masterFunctionAdmin = firebaseApp.functions('asia-northeast1').httpsCallable('getQrBarcodeTimeSensitiveValue')
      masterFunctionAdmin({
        hashedCorrectAnswer: this.$route.params.qrValue
      }).then(result => {
        this.qrValue = result.data.value
      })
    },
    handleFocus () {
      this.fetchQrValue()
    }
  }
}
</script>

<style scoped>
.qr-code-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.qr-code {
  width: 100vmin;
  max-width: 100vmin;
  max-height: 100vmin;
}

.loading-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
